import './webpack-imports';
import { initCustomElements } from './custom-elements';
import { applyPolyfills } from './polyfills';
import mobile from './inits/mobile';
import headerMenu from './inits/header-menu';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/dist/ScrollTrigger';
import CustomEase from 'gsap/CustomEase';
import deviceWidth from './utils/viewport';
import preloader from './inits/preloader';
import barba from '@barba/core';
import barbaCss from '@barba/css';
import lazyView from './views/_lazy';
import { initBaseView } from './views/_base';
import BaseTransition from './transitions/base';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';

document.documentElement.classList.add('js-ready');

gsap.config({ nullTargetWarn: false });

gsap.registerPlugin(ScrollTrigger, CustomEase);

applyPolyfills().then(() => {
    initCustomElements();

    // Utils
    mobile.init();
    headerMenu.init();
    deviceWidth.init();
    calculateScrollbarWidth();

    initBaseView();

    preloader.init();

    barba.use(barbaCss);

    barba.init({
        transitions: [BaseTransition],
        views: [
            lazyView('index-page', () =>
                import(/* webpackChunkName: "index-page", webpackPrefetch: true */ './views/index').then(
                    (m) => m.default,
                ),
            ),
            lazyView('about-page', () =>
                import(/* webpackChunkName: "about-page" */ './views/about').then((m) => m.default),
            ),
            lazyView('contacts-page', () =>
                import(/* webpackChunkName: "contacts-page" */ './views/contacts').then((m) => m.default),
            ),
            lazyView('projects-page', () =>
                import(/* webpackChunkName: "projects-page" */ './views/projects').then((m) => m.default),
            ),
            lazyView('project-inner-page', () =>
                import(/* webpackChunkName: "project-inner-page" */ './views/project-inner').then((m) => m.default),
            ),
            lazyView('not-found-page', () =>
                import(/* webpackChunkName: "not-found-page" */ './views/404').then((m) => m.default),
            ),
            lazyView('text-page', () =>
                import(/* webpackChunkName: "privacy-policy-page" */ './views/text-page').then((m) => m.default),
            ),
            lazyView('text-page', () =>
                import(/* webpackChunkName: "ncookie-policy-page" */ './views/text-page').then((m) => m.default),
            ),
        ],
        prefetchIgnore: true,
        preventRunning: true,
        timeout: 5000,
        debug: NODE_ENV === 'development',
    });
});

// Always at the end
module.hot?.accept();
