let header: HTMLElement | null = null;
let section: HTMLElement[] = [];

const sectionHandlers = new WeakMap<Element, () => void>();

function headerChange(element: Element) {
    if (header) {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (!entry.isIntersecting) {
                        header?.classList.remove('is-' + (entry.target as any).dataset.theme);
                    } else {
                        setTimeout(() => {
                            header?.classList.add('is-' + (entry.target as any).dataset.theme);
                        }, 1);
                    }
                });
            },
            {
                rootMargin: '0% 0% -99% 0%',
                threshold: 0,
            },
        );
        observer.observe(element);
    }
}

function init() {
    header = document.querySelector('.js-header');
    let section = document.querySelectorAll('.js-section-dark');

    if (section.length > 0) {
        section.forEach((element) => {
            headerChange(element);

            const handleScroll = () => {
                headerChange(element);
            };

            sectionHandlers.set(element, handleScroll);

            window.addEventListener('scroll', handleScroll);
        });
    }
}

const destroy = () => {
    section.forEach((element) => {
        const handleScroll = sectionHandlers.get(element);

        if (handleScroll) {
            window.removeEventListener('scroll', handleScroll);
        }
    });
};

const _module = { init, destroy };

export default _module;
