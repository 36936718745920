import preloaderAnimation from './animations/preloader-loading';
import gsap from 'gsap';
import axios from 'axios';

const paths = document.querySelectorAll('.animate-svg');
const svg = document.querySelector<SVGElement>('.js-preloader-svg');
const animationDuration = 2;

const loadAsset = (asset: HTMLVideoElement): Promise<void> => {
    return new Promise((resolve) => {
        if (asset instanceof HTMLVideoElement) {
            if (asset.readyState === 4) {
                resolve();
            } else {
                asset.addEventListener('canplay', () => resolve(), { once: true });
                asset.addEventListener('error', () => resolve(), { once: true });
            }
        }
    });
};

function animatePath(path, duration) {
    const length = path.getTotalLength();
    path.style.strokeDasharray = length;
    path.style.strokeDashoffset = length;

    gsap.to(path, {
        strokeDashoffset: 0,
        duration: duration,
        ease: 'power1.inOut',
    });
}

function animateAllPaths(paths, duration) {
    paths.forEach((path) => {
        animatePath(path, duration);
    });
}

const init = async () => {
    const firstVideo = document.querySelector<HTMLVideoElement>('.js-intro-video');

    if (firstVideo && svg) {
        svg.style.opacity = 1;
        let firstVideoDuration = animationDuration;
        const videoUrl = firstVideo.currentSrc;
        const axiosInstance = axios.create();

        axiosInstance.interceptors.request.use((config) => {
            config.onDownloadProgress = (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                firstVideoDuration = (percentCompleted / 100) * animationDuration;
            };
            return config;
        });
        animateAllPaths(paths, firstVideoDuration);

        await loadAsset(firstVideo);
        preloaderAnimation.init();
    } else {
        preloaderAnimation.init();
    }
};

const _module = { init };

export default _module;
