const header = document.querySelector('.js-header');
const headerBurger = document.querySelector('.js-header-menu-open');

const headerLinks = Array.from(document.querySelectorAll('.js-header-link'));

const menuOpen = () => {
    if (header) {
        header.classList.toggle('is-active');
    }
};

const menuClose = () => {
    if (header) {
        header.classList.remove('is-active');
    }
};

const init = () => {
    if (headerBurger) {
        headerBurger.addEventListener('click', menuOpen);
    }
};

const destroy = () => {
    if (headerBurger && header) {
        headerBurger.removeEventListener('click', menuOpen);
    }
};

const initForPage = () => {
    const sections = Array.from(document.querySelectorAll('.js-section'));

    headerLinks.forEach((link) => {
        link.addEventListener('click', menuClose);
    });

    sections.forEach((section) => {
        section.addEventListener('click', menuClose);
    });
};

const destroyForPage = () => {
    const sections = Array.from(document.querySelectorAll('.js-section'));

    sections.forEach((section) => {
        section.removeEventListener('click', menuClose);
    });

    headerLinks.forEach((link) => {
        link.removeEventListener('click', menuClose);
    });
};

const _module = { init, destroy, initForPage, destroyForPage };

export default _module;
