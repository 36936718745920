import type { ITransitionPage } from '@barba/core';
import gsap, { Power3 } from 'gsap';
const footer = document.querySelector('.js-footer');

export default {
    beforeLeave({ current }): Promise<void> {
        return new Promise((resolve) => {
            const sections = Array.from(current.container.querySelectorAll('.js-section'));
            document.documentElement.classList.add('page-transition');

            gsap.to([sections, footer], {
                duration: 0.7,
                opacity: 0,
                ease: Power3.easeOut,
                onComplete: () => {
                    setTimeout(resolve, 100);
                },
            });
        });
    },

    enter(): Promise<void> {
        return new Promise((resolve) => {
            window.scrollTo({ top: 0, behavior: 'auto' });
            resolve();
        });
    },

    afterEnter({ next }) {
        const sections = Array.from(next.container.querySelectorAll('.js-section'));
        document.documentElement.classList.remove('page-transition');

        gsap.to([footer, sections], {
            opacity: 1,
        });

        window.scrollTo({ top: 0, behavior: 'auto' });
    },
} as ITransitionPage;
