import createFormSender from '../../modules/ajax-form-sender';
import createValidator from '../../modules/validator';
import SlimSelect from 'slim-select';

export const FORM_SELECTOR = '.js-ajax-form';

export const formsDataMap = new WeakMap<
    HTMLFormElement,
    {
        handleSubmit: EventListener;
        projectTypeSelect: SlimSelect;
        hideTimeout: NodeJS.Timeout | null;
    }
>();

const showMessageClosure = (statusType: 'success' | 'error' | 'sending') => (form: HTMLFormElement) => {
    const statuses = form.querySelectorAll('.js-form__status');
    const activeStatus = form.querySelector(`.js-form__status-${statusType}`);

    statuses.forEach((status) => {
        status.classList.remove('form__status--active');
    });

    if (activeStatus) {
        activeStatus.classList.add('form__status--active');
    }
};

const showSuccessMessage = showMessageClosure('success');
const showSendingMessage = showMessageClosure('sending');
const showErrorMessage = showMessageClosure('error');

const hideFormMessages = (form: HTMLFormElement) => {
    const statuses = form.querySelectorAll('.js-form__status');

    statuses.forEach((status) => {
        status.classList.remove('form__status--active');
    });
};

const clearAntispamInput = (form: HTMLFormElement) => {
    const checkInput = form.querySelector<HTMLInputElement>('input[name="check_val"]');

    if (checkInput) {
        checkInput.value = '';
    }
};

const init = (container: Element | Document = document) => {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(FORM_SELECTOR));

    forms.forEach((form) => {
        let hideTimeout: NodeJS.Timeout | null = null;

        const validator = createValidator(form, {
            scrollToInvalidInputOptions: {
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            },
        });

        const sender = createFormSender(form, {
            shouldClearInputs: true,

            onBeforeSend: () => {
                showSendingMessage(form);
                clearAntispamInput(form);
            },
            onSuccess: ({ success }) => {
                if (success) {
                    showSuccessMessage(form);
                } else {
                    showErrorMessage(form);
                }
            },
            onError: () => {
                showErrorMessage(form);
            },
            onComplete: () => {
                if (hideTimeout) {
                    clearTimeout(hideTimeout);
                }

                hideTimeout = setTimeout(() => hideFormMessages(form), 5000);
            },
        });

        const projectTypeInput = form.querySelector<HTMLSelectElement>('.js-form__input-select');

        if (projectTypeInput) {
            const projectTypeSelect = new SlimSelect({
                select: projectTypeInput,
                showSearch: false,
                searchFocus: false,
            });

            const handleSubmit = (event: any) => {
                event.preventDefault();

                const submitButton = form.querySelector<HTMLButtonElement>('button.btn[type="submit"]');

                const isFormValid = validator.validate();

                if (isFormValid) {
                    if (submitButton) {
                        submitButton.disabled = true;
                    }

                    sender.send();

                    projectTypeSelect.set('');
                    projectTypeSelect.beforeClose?.();
                }
            };

            form.addEventListener('submit', handleSubmit);

            formsDataMap.set(form, {
                handleSubmit,
                projectTypeSelect: projectTypeSelect,
                hideTimeout: hideTimeout,
            });
        }
    });
};

const destroy = (container: Element | Document = document) => {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(FORM_SELECTOR));

    forms.forEach((form) => {
        const formData = formsDataMap.get(form);

        if (formData) {
            form.removeEventListener('submit', formData.handleSubmit);
            formData.projectTypeSelect.destroy();

            if (formData.hideTimeout) {
                clearTimeout(formData.hideTimeout);
            }
        }
    });
};

const _module = { init, destroy };

export default _module;
