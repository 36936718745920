import gsap, { Power3 } from 'gsap';
import { formsDataMap } from './ajax-forms';
import { DEVICE_WIDTH } from '../utils/viewport';

let modal: HTMLElement | null = null;
let modalCloseButton: HTMLElement | null = null;
let modalBackground: HTMLElement | null = null;

let openModalButtons: HTMLElement[] = [];
let openModalButtonsWithPrivateType: HTMLElement[] = [];
let openModalButtonsWithFederalType: HTMLElement[] = [];

const DECOR_SELECTOR = '.js-start-project-modal__decor';

let openFormButton: HTMLButtonElement | null = null;
let closeFormButton: HTMLElement | null = null;
let formContainer: HTMLElement | null = null;

let form: HTMLFormElement | null = null;

let projectTypeInput: HTMLElement | null = null;

let scrollingWrapper: HTMLElement | null = null;

const playModalDecorAnimation = () => {
    gsap.fromTo(
        DECOR_SELECTOR,
        {
            scaleX: 0,
            transformOrigin: 'left',
        },
        {
            scaleX: 1,
            delay: 0.4,
            ease: Power3.easeOut,
            duration: 1.4,
        },
    );
};

const openForm = () => {
    if (formContainer && form && openFormButton && scrollingWrapper) {
        formContainer.classList.add('start-project-modal__form-container--active');
        form.classList.add('start-project-modal__form--active');
        openFormButton.disabled = true;

        scrollingWrapper.classList.add('start-project-modal__scrolling-wrapper--active');
    }
};

const closeModal = () => {
    if (formContainer && form && openFormButton && scrollingWrapper) {
        formContainer.classList.remove('start-project-modal__form-container--active');
        form.classList.remove('start-project-modal__form--active');
        openFormButton.disabled = false;

        scrollingWrapper.classList.remove('start-project-modal__scrolling-wrapper--active');
        scrollingWrapper.scrollTo({ top: 0, behavior: 'smooth' });
    }
};

const openModal = (isProjectType = false) => {
    if (modal) {
        modal.classList.add('start-project-modal--active');
        playModalDecorAnimation();
    }

    if (projectTypeInput && isProjectType) {
        projectTypeInput.classList.add('form__input--hint-active');
        openForm();
    }

    document.body.classList.add('no-scroll');
};

const closeStartProjectModal = () => {
    if (modal && formContainer) {
        modal.classList.remove('start-project-modal--active');
        closeModal();
    }

    document.body.classList.remove('no-scroll');
};

const openModalWithoutType = () => {
    openModal();
};

const openModalWithPrivateType = () => {
    if (form) {
        const formHandlers = formsDataMap.get(form);

        if (formHandlers) {
            formHandlers.projectTypeSelect.set((formHandlers.projectTypeSelect.data.data[1] as any).value);
        }
    }

    openModal(true);
};

const openModalWithFederalType = () => {
    if (form) {
        const formHandlers = formsDataMap.get(form);

        if (formHandlers) {
            formHandlers.projectTypeSelect.set((formHandlers.projectTypeSelect.data.data[2] as any).value);
        }
    }

    openModal(true);
};

const init = () => {
    modal = document.querySelector('.js-start-project-modal');
    modalCloseButton = document.querySelector('.js-start-project-modal__close-button');
    modalBackground = document.querySelector('.js-start-project-modal__background');

    openModalButtons = Array.from(document.querySelectorAll('[data-open-start-project-modal]'));
    openModalButtonsWithPrivateType = Array.from(document.querySelectorAll('[data-open-start-project-modal-private]'));
    openModalButtonsWithFederalType = Array.from(document.querySelectorAll('[data-open-start-project-modal-federal]'));

    openFormButton = document.querySelector<HTMLButtonElement>('.js-start-project-modal__open-form-button');
    closeFormButton = document.querySelector('.js-start-project-modal__close-form-button');
    formContainer = document.querySelector('.js-start-project-modal__form-container');

    form = document.querySelector<HTMLFormElement>('.js-start-project-modal__form');

    projectTypeInput = document.querySelector('.js-start-project-modal__project-type-input');

    scrollingWrapper = document.querySelector('.js-start-project-modal__scrolling-wrapper');

    if (modalCloseButton && modalBackground) {
        modalCloseButton.addEventListener('click', closeStartProjectModal);
        modalBackground.addEventListener('click', closeStartProjectModal);
    }

    if (openModalButtons && openModalButtonsWithPrivateType && openModalButtonsWithFederalType) {
        openModalButtons.forEach((button) => {
            button.addEventListener('click', openModalWithoutType);
        });

        openModalButtonsWithPrivateType.forEach((button) => {
            button.addEventListener('click', openModalWithPrivateType);
        });

        openModalButtonsWithFederalType.forEach((button) => {
            button.addEventListener('click', openModalWithFederalType);
        });
    }

    if (formContainer && openFormButton && closeFormButton) {
        openFormButton.addEventListener('click', openForm);
        closeFormButton.addEventListener('click', closeModal);
    }
};

const destroy = () => {
    if (modalCloseButton && modalBackground) {
        modalCloseButton.removeEventListener('click', closeStartProjectModal);
        modalBackground.removeEventListener('click', closeStartProjectModal);
    }

    if (openModalButtons && openModalButtonsWithPrivateType && openModalButtonsWithFederalType) {
        openModalButtons.forEach((button) => {
            button.removeEventListener('click', openModalWithoutType);
        });

        openModalButtonsWithPrivateType.forEach((button) => {
            button.removeEventListener('click', openModalWithPrivateType);
        });

        openModalButtonsWithFederalType.forEach((button) => {
            button.removeEventListener('click', openModalWithFederalType);
        });
    }

    if (formContainer && openFormButton && closeFormButton) {
        openFormButton.removeEventListener('click', openForm);
        closeFormButton.removeEventListener('click', closeModal);
    }
};

const _module = { init, destroy };

export default _module;
