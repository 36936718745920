import debounce from 'lodash.debounce';
import { isMobileDevice } from '../utils/mobile';

function fixVh() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

const fixVhResize = debounce(() => {
    fixVh();
}, 50);

function init() {
    if (isMobileDevice()) {
        document.documentElement.classList.add('is-touch');
        fixVh();
        window.addEventListener('resize', fixVhResize);
        window.addEventListener('orientationchange', fixVhResize);
    }
}

const _module = { init };

export default _module;
