import barba from '@barba/core';
import ajaxForms from '../inits/ajax-forms';
import floatedLabels from '../inits/floated-labels';
import formAccordions from '../inits/form-accordions';
import headerMenu from '../inits/header-menu';
import startProjectModal from '../inits/start-project-modal';
import headerTheme from '../inits/header-theme';
import videosOnPage from '../utils/videos';
import { clearScrollTriggers } from '../utils/clear-scrolltriggers';

export const initBaseView = () => {
    barba.hooks.beforeEnter(() => {
        headerMenu.initForPage();
        startProjectModal.init();
        headerTheme.init();
        ajaxForms.init();
        floatedLabels.init();
        formAccordions.init();
    });

    barba.hooks.afterEnter((transition) => {
        videosOnPage.init(transition?.next.container);
    });

    barba.hooks.beforeLeave(() => {
        headerMenu.destroyForPage();
        startProjectModal.destroy();
        headerTheme.destroy();
        ajaxForms.destroy();
        floatedLabels.destroy();
        formAccordions.destroy();

        clearScrollTriggers();
    });
};
