import { formsDataMap, FORM_SELECTOR } from './ajax-forms';

const ACTIVE_CLASS = 'form__input--hint-active';

interface IInputHandlers {
    handleFocus: () => void;
    handleBlur: () => void;
}

const formHandlersMap = new WeakMap<
    HTMLFormElement,
    {
        inputs: IInputHandlers[];
        select: IInputHandlers;
    }
>();

function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(FORM_SELECTOR));

    forms.forEach((form) => {
        const handleInputFocusClosure = (input: HTMLInputElement | HTMLSelectElement) => () => {
            input.classList.add(ACTIVE_CLASS);
        };

        const handleInputBlurClosure = (input: HTMLInputElement | HTMLSelectElement) => () => {
            if (!input.value) {
                input.classList.remove(ACTIVE_CLASS);
            }
        };

        const formData = formsDataMap.get(form);
        const inputs = Array.from(form.querySelectorAll<HTMLInputElement>('.js-form__input'));
        const selectInput = form.querySelector<HTMLInputElement>('.js-form__input-select');

        if (formData && selectInput) {
            const inputHandlers: IInputHandlers[] = [];

            inputs.forEach((input) => {
                const handleFocus = handleInputFocusClosure(input);
                const handleBlur = handleInputBlurClosure(input);

                input.addEventListener('focus', handleFocus);
                input.addEventListener('blur', handleBlur);

                inputHandlers.push({ handleFocus, handleBlur });
            });

            const handleProjectTypeFocus = handleInputFocusClosure(selectInput);
            const handleProjectTypeBlur = handleInputBlurClosure(selectInput);

            formData.projectTypeSelect.beforeOpen = handleProjectTypeFocus;
            formData.projectTypeSelect.beforeClose = handleProjectTypeBlur;

            formHandlersMap.set(form, {
                inputs: inputHandlers,
                select: { handleFocus: handleProjectTypeFocus, handleBlur: handleProjectTypeBlur },
            });
        }
    });
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll<HTMLFormElement>(FORM_SELECTOR));

    forms.forEach((form) => {
        const formHandlers = formHandlersMap.get(form);
        const inputs = Array.from(form.querySelectorAll<HTMLInputElement>('.js-form__Input'));
        const selectInput = form.querySelector<HTMLInputElement>('.js-form__input-select');

        if (formHandlers && selectInput) {
            inputs.forEach((input, inputIndex) => {
                const inputHandlers = formHandlers.inputs[inputIndex];

                input.removeEventListener('focus', inputHandlers.handleFocus);
                input.removeEventListener('blur', inputHandlers.handleBlur);
            });
        }
    });
}

const _module = { init, destroy };

export default _module;
