export const DEVICE_WIDTH = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
};

const init = () => {
    if (window.innerWidth < 768) {
        DEVICE_WIDTH.isMobile = true;
    } else if (innerWidth < 1024) {
        DEVICE_WIDTH.isTablet = true;
    } else {
        DEVICE_WIDTH.isDesktop = true;
    }
};

const _module = { init };

export default _module;
