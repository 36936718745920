import gsap, { Power3, Power4 } from 'gsap';
import CustomEase from 'gsap/CustomEase';

const ANIMATION_SELECTORS = {
    container: '.js-preloader',
    indicatorsContainer: '.js-preloader__indicators',
    indicator1: '.js-preloader__indicator-1',
    indicator2: '.js-preloader__indicator-2',
    indicator3: '.js-preloader__indicator-3',
};
const firstVideo = document.querySelector<HTMLVideoElement>('.js-intro-video');

const init = () => {
    const timeline = gsap.timeline();

    document.body.classList.add('no-scroll');

    timeline.to(ANIMATION_SELECTORS.indicator3, {
        y: '-2000%',
        duration: 2.5,
        ease: Power4.easeInOut,
    });

    timeline.to(ANIMATION_SELECTORS.indicator2, {
        y: '-1000%',
        duration: 2,
        ease: Power3.easeInOut,
        delay: -2,
        onStart: () => {
            if (firstVideo) {
                firstVideo.pause();
                firstVideo.currentTime = 0;
            }
        },
    });

    timeline.to(ANIMATION_SELECTORS.indicator1, {
        y: '-100%',
        duration: 1.5,
        delay: -1.5,
        ease: 'expo.inOut',
    });

    timeline.to(ANIMATION_SELECTORS.container, {
        opacity: 0,
        ease: CustomEase.create('custom', 'M0,0 C0.272,0 0.372,0.506 0.372,0.506 0.372,0.506 0.458,1 1,1 '),
        duration: 1.2,
        delay: -0.4,
        onComplete: () => {
            const preloader = document.querySelector(ANIMATION_SELECTORS.container);
            if (preloader) {
                preloader.remove();
            }
            if (firstVideo) {
                firstVideo.play();
            }
            document.body.classList.remove('no-scroll');
        },
    });
};

const _module = { init };

export default _module;
