const FORM_CONTAINER_SELECTOR = '.js-form-accordion__form-container';

const accordionsHandlersMap = new WeakMap<
    HTMLElement,
    {
        openForm: () => void;
        closeForm: () => void;
    }
>();

const init = (container: Element | Document = document) => {
    const formContainers = Array.from(container.querySelectorAll<HTMLElement>(FORM_CONTAINER_SELECTOR));

    formContainers.forEach((formContainer) => {
        const openFormButton = formContainer.querySelector<HTMLButtonElement>('.js-form-accordion__open-button');
        const closeFormButton = formContainer.querySelector<HTMLButtonElement>('.js-form-accordion__close-button');
        const form = formContainer.querySelector<HTMLFormElement>('.js-form-accordion__form');

        const openForm = () => {
            if (formContainer && form && openFormButton) {
                formContainer.classList.add('pre-footer-contacts__form-container--active');
                form.classList.add('pre-footer-contacts__form--active');
                openFormButton.disabled = true;
            }
        };

        const closeForm = () => {
            if (formContainer && form && openFormButton) {
                formContainer.classList.remove('pre-footer-contacts__form-container--active');
                form.classList.remove('pre-footer-contacts__form--active');
                openFormButton.disabled = false;
            }
        };

        if (openFormButton && closeFormButton) {
            openFormButton.addEventListener('click', openForm);
            closeFormButton.addEventListener('click', closeForm);
        }

        accordionsHandlersMap.set(formContainer, { openForm, closeForm });
    });
};

const destroy = (container: Element | Document = document) => {
    const formContainers = Array.from(container.querySelectorAll<HTMLElement>(FORM_CONTAINER_SELECTOR));

    formContainers.forEach((formContainer) => {
        const openFormButton = formContainer.querySelector<HTMLButtonElement>('.js-form-accordion__open-button');
        const closeFormButton = formContainer.querySelector<HTMLButtonElement>('.js-form-accordion__close-button');

        const handlers = accordionsHandlersMap.get(formContainer);

        if (openFormButton && closeFormButton && handlers) {
            openFormButton.addEventListener('click', handlers.openForm);
            closeFormButton.addEventListener('click', handlers.closeForm);
        }
    });
};

const _module = { init, destroy };

export default _module;
