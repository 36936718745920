export default {
    ru: {
        EMPTY_FIELD: '(Поле обязательно)',
        INVALIDATED_EMAIL: '(Эл. почта некорректна)',
        INVALIDATED_PHONE: '(Номер телефона некорректен)',
        INVALIDATED_SELECT: '(Выберите значение из списка)',
        INVALIDATED_EQUALS: '(Значение полей должно совпадать)',
    },
    en: {
        EMPTY_FIELD: 'This field must not be empty',
        INVALIDATED_EMAIL: 'Enter a valid email address',
        INVALIDATED_PHONE: 'Enter a valid phone number',
        INVALIDATED_SELECT: 'Choose an option',
        INVALIDATED_EQUALS: 'Values must be equal',
    },
};
